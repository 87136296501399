<template>
	<div class="container-register-sleep">
	<SWNavButton
		@click="$router.push({ name: 'login' })" 
		class="btn-nav-ragister"
		:size="'small'"
		>Back</SWNavButton
	>
	<div class="box-register-sleep">
		<div class="img-sleep-register">
			<img src="../../assets/img/sleepNewLogo.png" alt="" />
		</div>

		<div class="title-sleep-register">
			<p>Password recovery</p>
			<p class="subtitle">To reset your password, use the email address or phone number for Sleep-well.coach</p>
		</div>

		<div class="reg-box">
			<button @click="toggleIsEmailForm" class="paragraf-current" :class="isEmailForm ? 'active' : ''">
			Email
			</button>
			<button @click="toggleIsEmailForm" class="paragraf-current" :class="!isEmailForm ? 'active' : ''">
			Phone
			</button>
		</div>
		<!-- <router-view></router-view> -->
		<form @submit.prevent="submitHandler">
			<div class="register-input">
			<SWInput
				v-show="isEmailForm"
				class="margin-inp"
				name="email"
				v-model="email"
				@input="$v.email.$touch()"
				placeholder="Email"
				:isError="isEmailError"
				type="email"
				:errorMessage="emailError"
			></SWInput>

			<SWInput
				v-show="!isEmailForm"
				class="margin-inp"
				name="phone"
				v-model="phone"
				@input="$v.phone.$touch()"
				placeholder="Phone"
				:isError="isPhoneError"
				type="phone"
				:errorMessage="phoneError"
				v-phone-number
			></SWInput>
			
			<SWButton :disabled="disabled" type="submit" variant="primari" size="big"
				>Continue</SWButton
			>
			</div>
		</form>
	</div>
 </div>
 </template>
 
 <script>
 import { SWButton, SWInput, SWNavButton } from 'sw-ui-kit';
 import { validationMixin } from 'vuelidate';
 import { required, minLength, maxLength } from 'vuelidate/lib/validators';
 import { hasNumberPhone, hasEmail } from '../../validation/validation';
 

 export default {

 components: {
	SWInput,
	SWButton,
	SWNavButton
 },
 mixins: [validationMixin],
 

 data(){
	return {
		email: '',
		phone: null,
		isEmailForm: true,
	}
 },
 validations: {
	email: { required, hasEmail },
	phone: { required, maxLength: maxLength(21), minLength: minLength(10), hasNumberPhone },
 },
 mounted() {
		
	},

	methods: {
		toggleIsEmailForm() {
			this.isEmailForm = !this.isEmailForm;
		},

		async submitHandler() {
		
		if(this.email){
			localStorage.setItem('email', this.email);
			this.$router.push({ name: 'reset-by-email', params: { email: this.email } });
		} else if(this.phone){
			this.phone = this.phone.charAt(0) === '+' ? this.phone : '+' + this.phone;
			this.$router.push({ name: 'reset-by-phone', params: { phone: this.phone } });
		}

      },
	},
	computed:{
		disabled() {
			return ( this.isEmailForm && this.emailError != '') || ( !this.isEmailForm && this.phoneError != '');
		},
		isEmailError() {
			return this.emailError != '';
		},
		isPhoneError() {
			return this.phoneError != '';
		},
		emailError() {
			if (!this.$v.email.required) return 'The field must be filled';
			if (!this.$v.email.hasEmail)
				return 'The email can have Latin capital and small letters,numbers and a special symbol (! # $ % & ‘ * + — / = ? ^ _ ` { | } ~;)';
			return '';
		},
		phoneError() {
			if (!this.$v.phone.required) return 'The field must be filled';
			if (!this.$v.phone.maxLength) return 'Phone must not exceed 20 numbers';
			if (!this.$v.phone.minLength) return 'Phone must be exceed 10 numbers';
			if (!this.$v.phone.hasNumberPhone) return 'Phone can have numbers special symbol "+ () -" and length max22 ';
			return '';
		},
	}
 };
 </script>

<style lang="sass" scoped>
.reg-box
	display: flex
	justify-content: center
.reg-box:nth-child(1)
	margin-right: 20px

.reg-box
	@media screen and (max-width:380px)
		margin-top: -10px
.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 15px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
	text-align: center

.subtitle
	font-family: 'Roboto', sans-serif
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	text-align: center
	color: #11151F
	margin-top: 16px
	margin-bottom: 16px	

.paragraf-current
	font-weight: 500
	line-height: 18.2px
	text-align: center
	color: #828282
	cursor: pointer
	padding: 15px
.paragraf-current.active
	color: #11151F
	text-decoration: underline
	
.register-input
	margin-bottom: 32px
	@media screen and (max-width:380px)
		margin-bottom: 10px
.margin-inp
	margin-bottom: 16px
.btn-nav-ragister
	margin-bottom: 20px
	font-weight: 400
	margin-top: 64px

@media screen and (max-width:1024px)
	.btn-nav-ragister
		margin-top: 16px
</style>